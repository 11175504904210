.header {
  /* display: flex; */
  padding: 0px !important;
}

.items {
  justify-content: end !important;
  background: #fff;
  width: 100%;
  display: flex;
  height: 100%;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
